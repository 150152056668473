/* eslint-disable no-unreachable */
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import Modal from 'react-modal';
import 'react-tabs/style/react-tabs.css';
// import SpyderIcon from '../Icons/spyder_icon.png';
// import GraphemIcon from '../Icons/graphem-logo.png';
import Default from '../ScannerViews/Default';
import Grid from '../ScannerViews/Grid';
import TabsView from '../ScannerViews/Tabs';
import MWView from '../ScannerViews/MWView';
import {getWatchlists, addSymbolToWatchlist, removeSymbolFromWatchlist} from '../Helpers/watchlist';
import AppContext from '../Context/AppContext';

const {REACT_APP_STANDALONE} = process.env;

Modal.setAppElement('#scan_scanner');

const layoutOptions = {
  tabs: 'tabs-layout',
  default: 'default-layout',
  grid: 'grid-layout',
  verticalStack: 'vertical-stack-layout',
  horizontalStack: 'horizontal-stack-layout',
};

const Scanners = ({theme, setTheme}) => {
  const [state, dispatch, wsSocket] = useOutletContext();
  const {
    scannersData,
    userData,
    groupCollection,
    groups,
    loadingGroups,
    showChart,
    secondaryGroups,
    inView,
    currentDate,
  } = state;
  const params = useParams();

  const [modalShow, setModalShow] = useState(false);
  const [scannerLayout, setScannerLayout] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [showUpcomingModal, setShowUpcomingModal] = useState(false);
  const [fetchingWatchlist, setFetchingWatchlist] = useState(false);
  const [updatingDate, setUpdatingDate] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [activeWatchlist, setActiveWatchlist] = useState(null);
  const [activeWatchlistSymbols, setActiveWatchlistSymbols] = useState([]);
  const [watchlists, setWatchlists] = useState(null);
  const [optionToView, setOptionToView] = useState(null);
  const [symbolToChart, setSymbolToChart] = useState(null);
  const [groupToChart, setGroupToChart] = useState(null);
  const [showAIModal, setShowAIModal] = useState(false);
  const [symbolForAI, setSymbolForAI] = useState(null);

  const handleAfterUpdatingWatchlist = (watchlistRes) => {
    if (!Object.keys(watchlistRes)?.length) {
      // dispatch({type: 'SET_WATCHLISTS_AND_ACTIVE_WATCHLISTS', payload: {watchlists: null, activeWatchlist: null}});
      return;
    }
    const {formattedSymbols} = watchlistRes[Object.keys(watchlistRes)[0]];
    // To Do: Handle switch to active watchlist
    // dispatch({
    //   type: 'SET_WATCHLISTS_AND_ACTIVE_WATCHLISTS',
    //   payload: {
    //     watchlists: watchlistRes,
    //     activeWatchlist: Object.keys(formattedSymbols ?? {})?.length ? formattedSymbols : {},
    //   },
    // });

    setWatchlists(watchlistRes);
    if (Object.keys(watchlistRes)?.length && Object.keys(formattedSymbols ?? {})?.length) {
      setActiveWatchlist(formattedSymbols);
      const symbolKeys = Object.keys(formattedSymbols);
      if (symbolKeys?.length) {
        const flattenSymbols = symbolKeys.reduce((acc, key) => {
          if (formattedSymbols[key]?.length) {
            return [...acc, ...formattedSymbols[key]];
          }
          return acc;
        }, []);
        setActiveWatchlistSymbols(flattenSymbols);
      }
    } else {
      setActiveWatchlistSymbols([]);
      setActiveWatchlist(null);
    }
  };

  const handleAddToWatchlist = useCallback(async (group, symbol, addToList = true) => {
    if (!symbol) {
      throw new Error('No symbol provided');
    }

    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso');
    }
    if (!token) {
      throw new Error('No token provided');
    }

    try {
      if (addToList) {
        const watchlistRes = await addSymbolToWatchlist({token, symbol, group});
        handleAfterUpdatingWatchlist(watchlistRes);
      } else {
        const watchlistRes = await removeSymbolFromWatchlist({token, symbol});
        handleAfterUpdatingWatchlist(watchlistRes);
      }
    } catch (e) {
      throw new Error('Error adding symbol to watchlist');
    }
  }, []);

  useEffect(() => {
    const scData = scannersData?.find((s) => s.slug === params?.id);
    const allowedWatchlist = scData?.allowedWatchlist ?? false;
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) {
      token = localStorage.getItem('scanner-sso');
    }
    if (allowedWatchlist && token && !watchlists && !activeWatchlist && !fetchingWatchlist && attempts) {
      setFetchingWatchlist(true);
      getWatchlists({token})
        .then((watchlistRes) => {
          handleAfterUpdatingWatchlist(watchlistRes);
          setFetchingWatchlist(false);
        })
        .finally(() => {
          setAttempts((att) => (att ? att - 1 : 0));
        });
    }
  }, [scannersData, params, userData]);

  useEffect(() => {
    if (scannersData?.length && !scannerLayout) {
      const urlPath = window.location.pathname.split('/');
      if (!urlPath[2]) {
        return;
      }
      const scannerData = scannersData?.find((s) => s.slug === urlPath[2]);
      const {layout} = scannerData ?? {layout: 'default-layout'};
      if (layout) {
        Object.values(layoutOptions).forEach((option) =>
          document.getElementsByTagName('body')[0].classList.toggle(option, layout === option),
        );
        setScannerLayout(layout);
      }
    }
  }, [scannersData]);

  const handleClose = useCallback(() => setModalShow(false), []);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const getSymbolData = useCallback((symbol, gr) => {
    const grName = typeof gr === 'string' ? gr : gr?.group;
    // dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol, group: grName}});
    setSymbolToChart(symbol);
    setGroupToChart(grName);
    setTimeout(() => {
      openModal();
    }, 1000);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    // dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol: null, group: null}});
    setSymbolToChart(null);
    setGroupToChart(null);
  }, []);

  const openOptionsModal = useCallback((row, group, key) => {
    if (!row || !row?.Symbol?.value) return;
    // dispatch({
    //   type: 'SET_OPTION_TO_VIEW',
    //   payload: {symbol: row?.Symbol?.value, group: typeof group === 'object' ? group?.group : group},
    // });
    setOptionToView({symbol: row?.Symbol?.value, group: typeof group === 'object' ? group?.group : group, key});
    setShowOptionsModal(true);
  }, []);

  const closeOptionsModal = useCallback(() => {
    setShowOptionsModal(false);
    setOptionToView(null);
  }, []);

  const openLockedModal = useCallback(() => {
    setShowLockedModal(true);
  }, []);

  const closeLockedModal = useCallback(() => {
    setShowLockedModal(false);
  }, []);

  const openUpcomingModal = useCallback(() => {
    setShowUpcomingModal(true);
  }, []);

  const closeUpcomingModal = useCallback(() => {
    setShowUpcomingModal(false);
  }, []);

  const openAIModal = useCallback((aiSymbol) => {
    setShowAIModal(true);
    if (aiSymbol === '') {
      return;
    }
    if (symbolForAI === aiSymbol) {
      return;
    }
    setSymbolForAI(aiSymbol);

  }, []);

  const closeAIModal = useCallback(() => {
    setShowAIModal(false);
    setSymbolForAI(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      modalShow,
      scannerLayout,
      modalIsOpen,
      showOptionsModal,
      showLockedModal,
      showUpcomingModal,
      theme,
      updatingDate,
      setTheme,
      watchlists,
      activeWatchlist,
      optionToView,
      activeWatchlistSymbols,
      getSymbolData,
      closeModal,
      openOptionsModal,
      closeOptionsModal,
      openLockedModal,
      closeLockedModal,
      openUpcomingModal,
      closeUpcomingModal,
      handleAddToWatchlist,
      setUpdatingDate,
      openAIModal,
      closeAIModal,
      showAIModal,
      symbolForAI,
    }),
    [
      activeWatchlistSymbols,
      modalShow,
      scannerLayout,
      modalIsOpen,
      showOptionsModal,
      showLockedModal,
      showUpcomingModal,
      theme,
      updatingDate,
      watchlists,
      activeWatchlist,
      showAIModal,
      openAIModal,
      closeAIModal,
      symbolForAI,
    ],
  );

  if (groupCollection && Object.keys(groupCollection ?? {})?.length && groups?.length && !loadingGroups) {
    return (
      <AppContext.Provider value={contextValue}>
        {scannerLayout === 'tabs-layout' ? (
          <TabsView
            groupToChart={groupToChart}
            symbolToChart={symbolToChart}
            modalIsOpen={modalIsOpen}
            showLockedModal={showLockedModal}
            optionToView={optionToView}
            showOptionsModal={showOptionsModal}
            showUpcomingModal={showUpcomingModal}
            groupCollection={groupCollection}
            groups={groups}
            inView={inView}
            currentDate={currentDate}
          />
        ) : null}
        {scannerLayout === 'mw-layout' ? (
          <MWView
            groupToChart={groupToChart}
            symbolToChart={symbolToChart}
            modalIsOpen={modalIsOpen}
            showLockedModal={showLockedModal}
            optionToView={optionToView}
            showOptionsModal={showOptionsModal}
            showUpcomingModal={showUpcomingModal}
            groupCollection={groupCollection}
            groups={groups}
            inView={inView}
            currentDate={currentDate}
          />
        ) : null}
        {scannerLayout !== 'tabs-layout' && scannerLayout !== 'grid-layout' && scannerLayout !== 'mw-layout' ? (
          <Default
            scannersData={scannersData}
            groupCollection={groupCollection}
            groups={groups}
            secondaryGroups={secondaryGroups}
            dispatch={dispatch}
            theme={theme}
            setTheme={setTheme}
            wsSocket={wsSocket}
            loadingGroups={loadingGroups}
            modalShow={modalShow}
            handleClose={handleClose}
            userData={userData}
            showChart={showChart}
            getSymbolData={getSymbolData}
            symbolToChart={symbolToChart}
          />
        ) : null}
      </AppContext.Provider>
    );
  }

  if (!groups || !groups?.length) {
    return (
      <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
        <div className="loading-user-wrapper">
          {/* <div className="mb-2 text-center animate-pulse">
            <img src={SpyderIcon} alt="Spyder Scanner" className="inline-block" />
          </div> */}
          <span>No Scanners Available</span>
        </div>
      </div>
    );
  }

  return (
    <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
      <div className="loading-user-wrapper">
        {/* <div className="mb-2 text-center animate-pulse">
            <img src={SpyderIcon} alt="Spyder Scanner" className="inline-block" />
          </div> */}
        <div className="mb-2 text-center">
          <div className="loader" />
        </div>
        {/* <span>Loading...</span> */}
      </div>
    </div>
  );
};

export default Scanners;
