/* eslint-disable react/no-danger */
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ReactComponent as SamSpeaksIcon} from '../../Icons/SAM-speaks.svg';

const AIView = ({symbolForAI}) => {
  const [loading, setLoading] = useState(true);
  const [aiData, setAIData] = useState(null);

  useEffect(() => {
    if (symbolForAI && process.env.REACT_APP_AI_URL) {
      setLoading(true);
      axios
        .get(process.env.REACT_APP_AI_URL, {
          params: {
            symbol: symbolForAI,
          },
        })
        .then((res) => {
          if (res?.data?.summary) {
            const {summary} = res.data;
            const urls = summary.match(/https?:\/\/[^\s]+/);
            if (urls?.length) {
              urls.forEach((url) => {
                const textWithATag = summary.replace(
                  url,
                  `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`,
                );
                const addLineBreak = textWithATag.replace(
                  'For more information on ',
                  '<br /><br />For more information on ',
                );
                setAIData(addLineBreak);
              });
            } else {
              setAIData(summary);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    return () => {
      setAIData(null);
    };
  }, [symbolForAI]);

  return (
    <div>
      <div className="flex items-start justify-between flex-col gap-2 relative">
        <div className="absolute h-14 w-14 top-1/2 -translate-y-1/2 left-5">
          <SamSpeaksIcon />
        </div>
        <div className="power-options">
          <h2 className="text-[28px] -mb-1 font-bold">SAMSpeaks AI Commentary</h2>
          {symbolForAI ? <h1>Symbol: {symbolForAI}</h1> : null}
        </div>
      </div>
      <div className="mt-4">
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="loader" />
          </div>
        ) : aiData ? (
          <div className="ai-data-wrap">
            <p className="ai-data" dangerouslySetInnerHTML={{__html: aiData}} />
            <br />
            <p className="ai-data">
              This summary was generated by AI. S.A.M. can make mistakes. Please check important info.
            </p>
          </div>
        ) : (
          <p>Error Loading Data</p>
        )}
      </div>
    </div>
  );
};

export default AIView;
